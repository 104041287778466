/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, Fragment } from 'react';
import './Header.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon, Bars3Icon } from '@heroicons/react/20/solid';
import useCurrentUser from 'modules/common/hooks/use-current-user/useCurrentUser.hook';
import { useAISidebar } from 'modules/common/hooks';
import session from '../../../../config/session/session.config';

const Header = () => {
  const { setIsAISidebarOpen } = useAISidebar();
  const { currentUser, setCurrentUser } = useCurrentUser();
  const [activeTab, setActiveTab] = useState('/');
  const [headerVisible, setHeaderVisible] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const headerHiddenPages = ['/facility/qrcode'];

  const validateSession = async () => {
    const user = await session.verifySession();
    // if (typeof user !== 'boolean') navigate(user.session.defaultRoute);
    if (user === false) navigate('/');
    return null;
  };

  const localStorageListenerForSession = () => {
    window.addEventListener('storage', (event) => {
      if (event.key === 'logOut') {
        setTimeout(() => {
          setCurrentUser(undefined);
          navigate('/');
        }, 100);
      }

      if (event.key === 'logIn') {
        setTimeout(async () => {
          const user = await session.getCurrentSession();

          setCurrentUser(user);
          if (user) navigate(user.session.defaultRoute);
          else session.verifySession();
        }, 100);
      }
    });
  };

  const logOut = () => {
    session.destroySession();
    navigate('/');
  };

  useEffect(() => {
    if (validateSession() !== null) {
      localStorageListenerForSession();
    }
  }, []);

  useEffect(() => {
    setActiveTab(location.pathname);
    if (location.pathname !== '/checklists') {
      setIsAISidebarOpen(false);
    }
    if (headerHiddenPages.find((page) => location.pathname.includes(page))) {
      setHeaderVisible(false);
    }
  }, [location]);

  return (
    <div>
      {headerVisible && (
        <div className="bg-colorPrimary items-center px-4 py-1 grid grid-cols-10">
          <div className="col-span-2">
            <Link to="/" className="flex items-center">
              <div className="ml-2 mt-0.5 text-xl text-white font-extrabold">Supplyblox QC</div>
            </Link>
          </div>
          <div className="col-span-6">
            <ul className="list-none m-0 p-0 overflow-hidden">
              <li className="float-left">
                <Link
                  className={`header-list ${activeTab === '/dashboard' && 'text-selectedHeaderTextColor'}`}
                  to="/dashboard"
                >
                  DASHBOARD
                </Link>
              </li>
              {/* <li className="float-left">
                <Link
                  className={`header-list ${activeTab.includes('forms') && 'text-selectedHeaderTextColor'}`}
                  to="/forms"
                >
                  FORMS
                </Link>
              </li> */}
              <li className="float-left">
                <Link
                  className={`header-list ${activeTab.includes('checklist') && 'text-selectedHeaderTextColor'}`}
                  to="/checklists"
                >
                  CHECKLISTS
                </Link>
              </li>
              <li className="float-left">
                <Link
                  className={`header-list ${activeTab.includes('schedule') && 'text-selectedHeaderTextColor'}`}
                  to="/schedule"
                >
                  SCHEDULE
                </Link>
              </li>
              {/* <li className="float-left">
                <Link
                  className={`header-list ${activeTab.includes('submissions') && 'text-selectedHeaderTextColor'}`}
                  to="/submissions"
                >
                  SUBMISSIONS
                </Link>
              </li> */}
              {/* <li className="float-left">
                <a
                  className={`header-list ${activeTab.includes('reports') && 'text-selectedHeaderTextColor'}`}
                  href="/reports"
                >
                  REPORTS
                </a>
              </li> */}
              <li className="float-left">
                <Link
                  className={`header-list ${activeTab.includes('user-management') && 'text-selectedHeaderTextColor'}`}
                  to="/user-management"
                >
                  USER MANAGEMENT
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-span-2">
            <div>
              <div className="float-right leading-10 relative flex justify-center item-center hover:cursor-pointer z-10">
                <Menu as="div" className="relative inline-block text-left ml-5">
                  <div>
                    <Menu.Button className="inline-flex justify-center w-full">
                      <div className="flex mr-2">
                        <span className="font-semibold text-headerTextColor">
                          Hi {currentUser?.user_details.username}
                        </span>
                        <div className="flex">
                          <Bars3Icon className="-mr-1 ml-1 h-5 w-5 m-auto text-headerTextColor" aria-hidden="true" />
                          <ChevronDownIcon
                            className="-mr-1 ml-1 h-5 w-5 m-auto text-headerTextColor"
                            aria-hidden="true"
                          />
                        </div>
                      </div>
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        <Menu.Item>
                          <button
                            type="button"
                            disabled
                            className="hover:bg-gray-100 hover:text-gray-900 text-gray-700 block px-4 py-2 text-sm w-40 text-left"
                          >
                            Profile
                          </button>
                        </Menu.Item>
                        <Menu.Item>
                          <button
                            type="button"
                            disabled
                            className="hover:bg-gray-100 hover:text-gray-900 text-gray-700 block px-4 py-2 text-sm w-40 text-left"
                          >
                            Settings
                          </button>
                        </Menu.Item>
                        <Menu.Item>
                          <button
                            type="button"
                            onClick={() => logOut()}
                            className="hover:bg-gray-100 hover:text-gray-900 text-gray-700 block px-4 py-2 text-sm w-40 text-left"
                          >
                            Sign out
                          </button>
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
