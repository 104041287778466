/* eslint-disable no-nested-ternary */
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { Button, Form, Input, message, Space } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { Loader } from '../../common/components';
import { CheckArrayContent } from '../add-checklist/components/checklist-detail/checklist-details.type';
import { ChecklistDetailData } from '../add-checklist/components/checklist-info/ChecklistInfo.component';
import ChecklistAnswer from './components/checklist-answer/ChecklistAnswer.component';
import ImagePreviewModal, { ImagePreviewHandle } from './components/image-preview-modal/ImagePreviewModal.component';
import fillChecklistApi from './fill-checklist.api';
import './fill-checklist.css';

const FillChecklist = () => {
  const [checklistDetails, setChecklistDetails] = useState<ChecklistDetailData>();
  const [checkArray, setCheckArray] = useState<CheckArrayContent[]>([]);
  const [checklistComplete, setChecklistComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const imagePreviewModalRef = useRef<ImagePreviewHandle>();
  const [form] = Form.useForm();
  const [searchParams] = useSearchParams();
  const userEmail = searchParams.get('email') || '';
  const userToken = searchParams.get('token') || '';

  const getChecklistDetail = useQuery(
    'Get Checklist Detail',
    async () => fillChecklistApi.getChecklistDetail(userEmail, userToken),
    {
      refetchOnWindowFocus: false,
      onSuccess: (response) => {
        const { data, status } = response;
        setIsLoading(false);
        if (status === 200) {
          const { checklist_template: checklist } = data;
          setChecklistDetails({
            checklist_id: checklist.checklist_id,
            checklist_name: checklist.checklist_name,
            checklist_desc: checklist.checklist_description,
            checklist_type_id: checklist.checklist_type_id,
            checklist_point_id: checklist.checklist_point_id,
            checklist_status: checklist.status === 'active',
          });
          setCheckArray(checklist.checks);
        } else {
          setIsError(true);
        }
      },
      onError: () => {
        setIsError(true);
      },
    },
  );

  useEffect(() => {
    getChecklistDetail.refetch();
    setIsError(!userEmail || !userToken);
  }, []);

  const getUpdatedFileName = (file: any) => {
    const blob = file?.slice(0, file.size, file.type) || '';
    const fileExtension = file.name.split('.').pop();
    const fileName = `${file.uid}.${fileExtension}`;
    const updatedFile = new File([blob], fileName, { type: 'image/png' });
    return updatedFile;
  };

  const submitChecklist = async (formData: any) => {
    setIsLoading(true);
    const { data, status } = await fillChecklistApi.submitChecklist(formData);
    setIsLoading(false);
    if (status !== 200 || data.status !== 'successful') {
      message.error('Failed to submit checklist. Please try again');
    } else {
      setChecklistComplete(true);
    }
  };

  const handleFinish = (values: any) => {
    const formData = new FormData();
    const updatedArray = checkArray.map((check) => {
      const updatedCheck = { ...check };
      updatedCheck.answer_comment = values[`comment_${check.check_id}`] ? values[`comment_${check.check_id}`] : '';
      if (check.answer_type === 'text') {
        const answerText = values[`check_${check.check_id}`];
        updatedCheck.answer_text = answerText || '';
      } else if (check.answer_type === 'single-selection') {
        const answerChoiceId = values[`check_${check.check_id}`];
        updatedCheck.answer_choice_id = answerChoiceId ? [answerChoiceId] : [];
      } else if (check.answer_type === 'multiple-selection') {
        const answerChoiceIds = values[`check_${check.check_id}`];
        updatedCheck.answer_choice_id = answerChoiceIds || [];
      } else if (check.answer_type === 'single-image-open') {
        const imageFile = values[`check_${check.check_id}`];
        if (imageFile) {
          const { file } = imageFile;
          const updatedFile = getUpdatedFileName(file);
          formData.append(updatedFile.name, updatedFile);
          updatedCheck.filename = [updatedFile.name];
        } else {
          updatedCheck.filename = [];
        }
      } else if (check.answer_type === 'multiple-image-open') {
        const imageFiles = values[`check_${check.check_id}`];
        if (imageFiles) {
          const allFiles = values[`check_${check.check_id}`].fileList;
          const fileNames: string[] = [];
          allFiles.forEach((file: any) => {
            const updatedFile = getUpdatedFileName(file.originFileObj);
            formData.append(updatedFile.name, updatedFile);
            fileNames.push(updatedFile.name);
          });
          updatedCheck.filename = fileNames;
        } else {
          updatedCheck.filename = [];
        }
      }
      return updatedCheck;
    });

    const payload = {
      checklist_id: checklistDetails?.checklist_id,
      checklist_name: checklistDetails?.checklist_name.trim(),
      checklist_description: checklistDetails?.checklist_desc.trim(),
      checklist_type_id: checklistDetails?.checklist_type_id,
      checklist_point_id: checklistDetails?.checklist_point_id,
      status: 'completed',
      checks: updatedArray,
    };

    formData.append('json', JSON.stringify(payload));
    formData.append('email', userEmail);
    formData.append('token', userToken);
    formData.append('marketplace', values.marketplace);
    formData.append('order_number', values.order_number);
    submitChecklist(formData);
  };

  const errorComponent = () => (
    <div className="m-auto w-3/5 bg-white p-8 rounded">
      <div>
        <ExclamationCircleIcon height={100} width={100} className="text-black m-auto" />
        <div className="text-2xl font-semibold text-center">Token Expired</div>
        <p className="text-center">This form is no longer available.</p>
      </div>
    </div>
  );

  const checklistCompleteComponent = () => (
    <div className="m-auto w-3/5 bg-white p-8 rounded">
      <CheckCircleIcon height={100} width={100} className="text-green-500 m-auto" />
      <h1 className="text-4xl font-semibold text-center">Thank You</h1>
      <p className="text-center">Your submission has been received</p>
    </div>
  );

  return (
    <div className="bg-colorPrimaryLight h-full p-10 overflow-auto">
      {isLoading && <Loader height="50px" width="50px" color="black" showTitle title="Loading..." />}
      <ImagePreviewModal ref={imagePreviewModalRef} />
      <div className="text-4xl font-bold text-center mb-4">Supplyblox QC</div>
      {isError ? (
        errorComponent()
      ) : checklistComplete ? (
        checklistCompleteComponent()
      ) : (
        <div className="m-auto w-3/5 bg-white px-8 pt-8 pb-14 rounded">
          <div className="text-2xl font-bold capitalize">{checklistDetails?.checklist_name}</div>
          <div className="text-md first-letter:capitalize">{checklistDetails?.checklist_desc}</div>
          <hr className="my-2 -mx-2" />
          <Form form={form} onFinish={handleFinish}>
            {checkArray?.map((check) => (
              <div key={check.check_id} className="mt-10">
                <p className="text-base font-semibold first-letter:capitalize">{check.check_text}</p>
                {(check.check_type === 'image' || check.check_type === 'text-image') &&
                  check.media?.map((item, index) => (
                    <Space key={item.check_media_id} direction="horizontal">
                      <button
                        type="button"
                        onClick={() => {
                          if (imagePreviewModalRef.current) imagePreviewModalRef.current.toggleModal(item.url);
                        }}
                      >
                        <img
                          src={item.url}
                          alt="Check"
                          className={`h-40 w-40 rounded ${index === 0 ? 'ml-0' : 'ml-4'} mb-4`}
                        />
                      </button>
                    </Space>
                  ))}
                {checklistDetails && <ChecklistAnswer answerType={check.answer_type} checkDetails={check} />}
              </div>
            ))}
            <div className="mt-10">
              <p className="text-base font-semibold first-letter:capitalize">Please enter your order number</p>
              <Form.Item
                name="order_number"
                rules={[
                  {
                    required: true,
                    message: 'Order number is required!',
                  },
                ]}
              >
                <Input type="number" placeholder="Order number" />
              </Form.Item>
            </div>
            <div className="mt-10">
              <p className="text-base font-semibold first-letter:capitalize">Please enter marketplace</p>
              <Form.Item
                name="marketplace"
                rules={[
                  {
                    required: true,
                    message: 'Marketplace is required!',
                  },
                ]}
              >
                <Input placeholder="Marketplace" />
              </Form.Item>
            </div>
          </Form>
        </div>
      )}

      {!checklistComplete && !isError && (
        <div className="m-auto w-3/5 mt-4">
          <Button
            type="primary"
            hidden={isLoading}
            onClick={() => form.submit()}
            className="!bg-colorPrimary hover:!bg-selectedHeaderTextColor !text-white !rounded-md !px-8 !border-0"
          >
            Submit
          </Button>
        </div>
      )}
    </div>
  );
};

export default FillChecklist;
